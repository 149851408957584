// single line question type codeing

import React, { useState, useRef, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import FillInTheBlanks from "../FillInTheBlanks";
import App1 from "../App1";
import axios from "axios";
import TrueFalse from "./TrueFalse";
import { EditableMathField, MathField, StaticMathField } from "react-mathquill";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import TagModal from "./TagModal";
import ImageOcclusion from "./ImageOcclusion";
import StyleIcon from "@mui/icons-material/Style";
import { useNavigate } from "react-router-dom";
import katex from "katex";
import "katex/dist/katex.min.css";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import sanitizeHtml from "sanitize-html";

const Flashcard = () => {
  const [cards, setCards] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [currentExplanation, setCurrentExplanation] = useState("");
  const [options, setOptions] = useState([""]);
  const [correctOption, setCorrectOption] = useState("");
  const [isAnswer, setIsAnswer] = useState(false);
  const [isExplanation, setIsExplanation] = useState(false);
  const [questionType, setQuestionType] = useState("Single Line Choice");
  const [clozeAnswers, setClozeAnswers] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);
  const inputRef = useRef(null);
  const [collapsed, setCollapsed] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editCardIndex, setEditCardIndex] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteCardId, setDeleteCardId] = useState(null); // Rename to reflect it holds the card's ID
  const [editQuestion, setEditQuestion] = useState("");
  const [editAnswers, setEditAnswers] = useState("");
  const [editExplanation, setEditExplanation] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false); // Controls dropdown visibility
  const [selectedOption, setSelectedOption] = useState(''); // State to track selected option
  // Tag Modal States
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagCardIndex, setTagCardIndex] = useState(null);
  const [tagModalPosition, setTagModalPosition] = useState({ top: 0, left: 0 });
  const navigate = useNavigate();

  const { subfolder_id } = useParams();

  useEffect(() => {
    if (subfolder_id) {
      fetchFlashcardsFromServer();
    }
  }, [subfolder_id]);

  const fetchFlashcardsFromServer = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.get(
        `${apiUrl}/subfolder/${subfolder_id}/sub/`
      );
      const fetchedCards = response.data.map((item) => ({
        id: item.id, // Ensure each card has an id
        question: item.statement,
        question_type: item.question_type,
        answer: item.answers,
        explanation: item.explanation,
        expanded: false, // Set expanded to false by default
        tags: item.tags, // Store the entire tags array
      }));
      setCards(fetchedCards);
    } catch (error) {
      console.error("Failed to fetch flashcards from server", error);
    }
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  

  

  const handleClozeAnswerChange = (index, value) => {
    const newClozeAnswers = [...clozeAnswers];
    newClozeAnswers[index] = value;
    setClozeAnswers(newClozeAnswers);
  };

  const addClozeField = () => {
    setClozeAnswers([...clozeAnswers, ""]);
  };
  const addFlashcardToServer = async (question, answer, explanation) => {
    const payload = {
      statement: question,
      created_by: "Admin",
      question_type: "SUB",
      answers: answer,
      explanation: explanation,
      tags: [],
    };

    const apiUrl = process.env.REACT_APP_BASE_URL;
    const response = await axios.post(
      `${apiUrl}/subfolder/${subfolder_id}/sub/`,
      payload
    );
  };

  
  const handleKeyDown = async (e) => {
    if (e.key === 'ArrowRight' && !isAnswer && !isExplanation) {
      e.preventDefault();
      setIsAnswer(true);
      setIsExplanation(false);
      setCurrentAnswer(''); // Reset the answer field
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } else if (e.key === 'Enter' && isAnswer && !isExplanation) {
      e.preventDefault();
      if (currentQuestion.trim() && currentAnswer.trim()) {
        setIsAnswer(false);
        setIsExplanation(true);
        setCurrentExplanation(''); // Reset the explanation field
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    } else if (e.key === 'Enter' && isExplanation) {
      e.preventDefault();
      await handleAddFlashcard(); // Call the new function to handle submission
    }
  };
  
  const handleAddFlashcard = async () => {
    // Check if inputs are valid
    if (!currentQuestion.trim() || !currentAnswer.trim() || !currentExplanation.trim()) {
      alert("Please fill in all fields before adding a flashcard.");
      return;
    }
  
    try {
      // Add flashcard to the server
      await addFlashcardToServer(currentQuestion, currentAnswer, currentExplanation);
  
      // Update the local state to reflect the new flashcard
      setCards([
        ...cards,
        {
          question: currentQuestion.trim(),
          answer: currentAnswer.trim(),
          explanation: currentExplanation.trim(),
          questionType,
          expanded: false,
        },
      ]);
  
      // Clear the input fields after successful submission
      resetFields(); // Ensure this is called here
  
      // Fetch flashcards after submission
      fetchFlashcardsFromServer(); 
    } catch (error) {
      console.error("Failed to add flashcard to server", error);
    }
  };
  
  
  
  
  
  const resetFields = () => {
    setCurrentQuestion(""); // Clear question
    setCurrentAnswer(""); // Clear answer
    setCurrentExplanation(""); // Clear explanation
    setIsAnswer(false); // Reset answer state
    setIsExplanation(false); // Reset explanation state
    if (inputRef.current) {
      inputRef.current.value = ""; // Clear the input directly if needed
    }
  };
  

  
  const handleToolbarClick = (type) => {
    setQuestionType(type);
    setCurrentQuestion("");
    setCurrentAnswer("");
    setCurrentExplanation("");
    setOptions([""]);
    setCorrectOption("");
    setIsAnswer(false);
    setIsExplanation(false);
    setClozeAnswers([]);
    setUserAnswers([]);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const addCloze = () => {
    if (currentQuestion.includes(currentAnswer)) {
      const clozeQuestion = currentQuestion.replace(currentAnswer, "_");
      setCurrentQuestion(clozeQuestion);
      setClozeAnswers([...clozeAnswers, currentAnswer]);
      setCurrentAnswer("");
    }
  };

  

  const handleUserAnswerChange = (index, value) => {
    const newUserAnswers = [...userAnswers];
    newUserAnswers[index] = value;
    setUserAnswers(newUserAnswers);
  };

  const toggleCardExpand = (index) => {
    const newCards = [...cards];
    newCards[index].expanded = !newCards[index].expanded;
    setCards(newCards);
  };

  const openEditModal = (index) => {
    setEditCardIndex(index);
    setEditQuestion(cards[index].question);
    setEditAnswers(cards[index].answer);
    setEditExplanation(cards[index].explanation);
    setEditModalOpen(true);
  };

  const handleEditChange = (index, value) => {
    const updatedAnswers = [...userAnswers]; // Create a copy of the answers array
    updatedAnswers[index] = value; // Update the answer at the specified index
    setUserAnswers(updatedAnswers); // Update the state with the updated answers
  };
  
  const handleUpdateCard = async () => {
    const updatedCard = {
      statement: editQuestion,
      created_by: "Admin",
      question_type: "SUB",
      answers: editAnswers,
      explanation: editExplanation,
      tags: [],
    };

    try {
      const cardId = cards[editCardIndex].id;
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.put(
        `${apiUrl}/subfolder/${subfolder_id}/sub/${cardId}/`,
        updatedCard
      );

      const updatedCards = [...cards];
      updatedCards[editCardIndex] = {
        ...updatedCards[editCardIndex],
        question: currentQuestion,
        answer: currentAnswer,
        explanation: currentExplanation,
      };
      // setCards(updatedCards);
      setEditModalOpen(false);
      fetchFlashcardsFromServer();
    } catch (error) {
      console.error("Failed to update flashcard on server", error);
    }
  };


  const handleCloseModal = () => {
    setEditModalOpen(false);
  };

  const openDeleteDialog = (id) => {
    setDeleteCardId(id); // Use the actual card's id
    console.log(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCard = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.delete(
        `${apiUrl}/subfolder/${subfolder_id}/sub/${deleteCardId}/`
      );
      const updatedCards = cards.filter((card) => card.id !== deleteCardId);
      setCards(updatedCards);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Failed to delete flashcard from server", error);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  // Tag Modal Functions
  const openTagModal = (index, event) => {
    const iconRect = event.target.getBoundingClientRect();
    setTagCardIndex(index);
    setTagModalPosition({
      top: iconRect.bottom + window.scrollY,
      left: iconRect.left + window.scrollX,
    });
    setTagModalOpen(true);
  };

  const handleTagAdded = (cardId, newTag) => {
    const updatedCards = cards.map((card) => {
      if (card.id === cardId) {
        return { ...card, tags: [...card.tags, newTag] };
      }
      return card;
    });
    setCards(updatedCards);
  };

  // const handleIconClick = () => {
  //   const hardCodedText = "sub"; // replace with your hard-coded text
  //   navigate(
  //     `/practiceparticularcard/${subfolder_id}/${encodeURIComponent(
  //       hardCodedText
  //     )}`
  //   );
  // };
  const handleIconClick = () => {
    // Toggle dropdown visibility when icon is clicked
    setDropdownVisible(!dropdownVisible);
  };

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);

    if (selectedValue === 'practiceCard') {
      // Logic to navigate to practice card page (you can add navigation here)
      const hardCodedText = "sub";
      navigate(

        `/practiceparticularcard/${subfolder_id}/${encodeURIComponent(
          hardCodedText
        )}`      // Example: history.push('/practice-card');
      )
    }

    else if (selectedValue === 'spacedrep') {
      // Logic to navigate to practice card page (you can add navigation here)
      const hardCodedText = "sub";
      navigate(

        `/practicespacedrep/${subfolder_id}/${encodeURIComponent(
          hardCodedText
        )}`      // Example: history.push('/practice-card');
      )
    }

    // Hide dropdown after selection
    setDropdownVisible(false);
  };
  const renderContent = (html) => {
    if (!html) return null;
  
    // Split content by LaTeX syntax (both block and inline)
    const parts = html.split(/(\$\$.+?\$\$|\$.+?\$)/g); 
  
    return (
      <div>
        {parts.map((part, index) => {
          // Handle Block-level LaTeX ($$...$$)
          if (part.startsWith("$$") && part.endsWith("$$")) {
            const latex = part.slice(2, -2); // Remove $$ delimiters
            try {
              const renderedLatex = katex.renderToString(latex, { displayMode: true });
              return (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: renderedLatex }}
                  className="rendered-content block-latex"
                />
              );
            } catch (err) {
              console.error("KaTeX block rendering failed:", err);
              return <span key={index}>{part}</span>;
            }
          }
          // Handle Inline LaTeX ($...$)
          else if (part.startsWith("$") && part.endsWith("$")) {
            const latex = part.slice(1, -1); // Remove $ delimiters
            try {
              const renderedLatex = katex.renderToString(latex, { displayMode: false });
              return (
                <span
                  key={index}
                  dangerouslySetInnerHTML={{ __html: renderedLatex }}
                  className="rendered-content inline-latex"
                />
              );
            } catch (err) {
              console.error("KaTeX inline rendering failed:", err);
              return <span key={index}>{part}</span>;
            }
          }
          // Sanitize and render remaining HTML content
          else {
            // Sanitize and render HTML content inline
            const cleanHtml = sanitizeHtml(part, {
              allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                "b", "i", "em", "strong", "span"
              ]),
              allowedAttributes: {
                ...sanitizeHtml.defaults.allowedAttributes,
                img: ["src", "alt"],
                a: ["href", "target"],
              },
              transformTags: {
                // Force all tags to be inline
                'p': 'span',
                'div': 'span',
                'br': 'span'
              }
            });
  
            return (
              <span
                key={index}
                dangerouslySetInnerHTML={{ __html: cleanHtml }}
                className="rendered-content inline-text"
              />
            );
          }
        })}
      </div>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="flashcard-container">
        <div className="input-container">
          {questionType === "True/False" && <TrueFalse />}
          {questionType === "Fill in the Blanks" && <FillInTheBlanks />}
          {questionType === "Multiple Choice" && <App1 />}
          {questionType === "Image Occlusion" && <ImageOcclusion />}
          {questionType === "Single Line Choice" && (
            <div>
              <div style={{ position: "relative", marginBottom: "20px" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h1><b>Single Line Questions</b></h1>

                  {/* Style Icon */}
                  <div style={{ position: 'relative' }}>
                    <StyleIcon onClick={handleIconClick} style={{ cursor: 'pointer' }} />

                    {/* Options (only visible when dropdownVisible is true) */}
                    {dropdownVisible && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '30px', // Adjust position based on your layout
                          right: '-30px', // Adjusted to move it further to the right
                          zIndex: 1,
                          backgroundColor: '#e3f2fd', // Light bluish shade
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          padding: '0', // Remove extra padding here
                        }}
                      >
                        <div
                          onClick={() => handleDropdownChange({ target: { value: 'practiceCard' } })}
                          style={{
                            cursor: 'pointer',
                            padding: '10px 15px', // Increased padding for better coverage
                            transition: 'background-color 0.3s ease',
                            whiteSpace: 'nowrap', // Prevent text from wrapping
                          }}
                          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#bbdefb')} // Lighter blue on hover
                          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                        >
                          Practice FlashCard
                        </div>
                        <div
                          onClick={() => handleDropdownChange({ target: { value: 'spacedrep' } })}
                          style={{
                            cursor: 'pointer',
                            padding: '10px 15px', // Increased padding for better coverage
                            transition: 'background-color 0.3s ease',
                            whiteSpace: 'nowrap', // Prevent text from wrapping
                          }}
                          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#bbdefb')} // Lighter blue on hover
                          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                        >
                          Practice with Spaced Repetition
                        </div>
                      </div>
                    )}
                  </div>




                </div>
                <div style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
                <ReactQuill
                  ref={inputRef} // Keep the ref to maintain the same behavior
                  value={currentQuestion} // Current question state
                  onChange={setCurrentQuestion} // Updates state on content change
                  placeholder="Enter your question"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"], // toggled buttons
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }], // custom button values
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }], // superscript/subscript
                      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                      [{ direction: "rtl" }], // text direction
                      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                      [{ font: [] }],
                      [{ align: [] }],
                      ["clean"], // remove formatting button
                    ],
                  }}
                  formats={[
                    "header",
                    "font",
                    "size",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "code-block",
                    "list",
                    "bullet",
                    "indent",
                    "link",
                    "image",
                    "video",
                    "color",
                    "background",
                    "align",
                    "script",
                    { name: 'preserve-spaces', type: 'inline' },
                  ]}
                  InputProps={{
                    style: {
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                      whiteSpace: "pre-wrap",
                    },
                  }}
                  style={{
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                />
        
                <div
                  className="mt-2"
                  style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                >
                  {renderContent(currentQuestion)}
                  </div>
                  </div>

              {/* Answer Field */}
              <div style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
                <ReactQuill
                  value={currentAnswer} // Current answer state
                  onChange={setCurrentAnswer} // Updates state on content change
                  placeholder="Enter your answer"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"], // toggled buttons
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }], // custom button values
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }], // superscript/subscript
                      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                      [{ direction: "rtl" }], // text direction
                      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                      [{ font: [] }],
                      [{ align: [] }],
                      ["clean"], // remove formatting button
                    ],
                  }}
                  formats={[
                    "header",
                    "font",
                    "size",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "code-block",
                    "list",
                    "bullet",
                    "indent",
                    "link",
                    "image",
                    "video",
                    "color",
                    "background",
                    "align",
                    "script",
                    { name: 'preserve-spaces', type: 'inline' },
                  ]}
                  InputProps={{
                    style: {
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                      whiteSpace: "pre-wrap",
                    },
                  }}
                  style={{
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                />
        
                <div
                  className="mt-2"
                  style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                >
                  {renderContent(currentAnswer)}
                </div>
              </div>
              </div>

              {/* Explanation Field */}
              <div style={{ position: "relative", marginBottom: "20px" }}>
                <ReactQuill
                  value={currentExplanation} // Current explanation state
                  onChange={setCurrentExplanation} // Updates state on content change
                  placeholder="Enter your explanation"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"], // toggled buttons
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }], // custom button values
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }], // superscript/subscript
                      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                      [{ direction: "rtl" }], // text direction
                      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                      [{ font: [] }],
                      [{ align: [] }],
                      ["clean"], // remove formatting button
                    ],
                  }}
                  formats={[
                    "header",
                    "font",
                    "size",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "code-block",
                    "list",
                    "bullet",
                    "indent",
                    "link",
                    "image",
                    "video",
                    "color",
                    "background",
                    "align",
                    "script",
                    { name: 'preserve-spaces', type: 'inline' },
                  ]}
                  InputProps={{
                    style: {
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                      whiteSpace: "pre-wrap",
                    },
                  }}
                  style={{
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                />
        
                <div
                  className="mt-2"
                  style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                >
                  {renderContent(currentExplanation)}
                </div>
              </div>
              <Button
  onClick={handleAddFlashcard} // Call the new function on button click
  variant="contained"
  sx={{ backgroundColor: '#1565c0', color: 'white', '&:hover': { backgroundColor: '#0d47a1' } }}
>
  add flashcard
</Button>


              <div>
                <ul className="card-list">
                  {cards.map((card, index) => (
                    <li
                    key={index}
                    className="card"
                    style={{
                      background: 'linear-gradient(to right, #dfe6f9, rgb(124 182 246))',
                      position: 'relative',
                      padding: '16px',
                      borderRadius: '8px',
                      marginBottom: '12px',
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                      maxWidth: 'calc(100% - 80px)', // Add maxWidth for controlled width
                      whiteSpace: 'pre-wrap', // Preserve whitespace and allow wrapping
                    }}
                  >
                      {/* Question */}
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            paddingRight: '40px', // Adjust padding if needed
            maxWidth: 'calc(100% - 70px)',
            whiteSpace: 'pre-wrap'
          }}
        >
          <strong style={{ marginRight: '2px' }}>Q:</strong>
          <span style={{ flex: 1 }}><strong>{renderContent(card.question)}</strong></span>
        </div>

        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            display: 'flex',
            gap: '10px',
          }}
        >
          <i
            className={`bi ${card.expanded ? 'bi-arrows-angle-contract' : 'bi-arrows-angle-expand'}`}
            title={card.expanded ? 'Contract' : 'Expand'}
            onClick={() => toggleCardExpand(index)}
            style={{ cursor: 'pointer' }}
          ></i>
          <i className="bi bi-pencil" title="Edit" onClick={() => openEditModal(index)} style={{ cursor: 'pointer' }}></i>
          <i className="bi bi-trash" title="Delete" onClick={() => openDeleteDialog(card.id)} style={{ cursor: 'pointer' }}></i>
          <i className="bi bi-tag" title="Tag" onClick={(e) => openTagModal(index, e)} style={{ cursor: 'pointer' }}></i>
        </div>

                      {card.expanded && (
                        <>
                            <>
            {/* Answer */}
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'pre-wrap', // Preserve whitespace and allow wrapping
              }}
            >
              <strong style={{ marginRight: '5px' }}>A:</strong>
              <span style={{ flex: 1 }}>{renderContent(card.answer)}</span>
            </div>
                
                {card.explanation && (
                  <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'pre-wrap', // Preserve whitespace and allow wrapping
                  }}
                >
                   <strong style={{ marginRight: '5px' }}>Explanation:</strong>
                   <span style={{ flex: 1 }}>{renderContent(card.explanation)}</span>
                  </div>
                              )}
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "10px",
                                  right: "10px",
                                }}
                              >
                                <p>
                                  Tags:
                                  {card.tags.map((tag, index) => (
                                    <span key={tag.id}>
                                      [{tag.name}
                                      {index < card.tags.length - 1 ? ", " : ""}
                                      ]
                                    </span>
                                  ))}
                                </p>
                              </div>
                            </>
                        
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`vertical-toolbar ${collapsed ? "collapsed" : ""}`}
        style={{ background: "linear-gradient(to right, #142be1, #44a6e9)" }}
      >
        <i
          className="bi bi-card-list"
          title="Single Line Choice Card"
          onClick={() => handleToolbarClick("Single Line Choice")}
        ></i>
        <i
          className="bi bi-pencil"
          title="Fill in the Blanks"
          onClick={() => handleToolbarClick("Fill in the Blanks")}
        ></i>
        <i
          className="bi bi-toggle-on"
          title="True/False"
          onClick={() => handleToolbarClick("True/False")}
        ></i>
        <i
          className="bi bi-ui-radios"
          title="Multiple Choice Q&A"
          onClick={() => handleToolbarClick("Multiple Choice")}
        ></i>
        <i
          className="bi bi-textarea-resize"
          title="Multiple Line Choice Card"
          onClick={() => handleToolbarClick("Multiple Line Choice")}
        ></i>
        <i
          className="bi bi-image"
          title="Image Occlusion"
          onClick={() => handleToolbarClick("Image Occlusion")}
        ></i>
        <i className="bi bi-trash" title="Delete"></i>
        <i className="bi bi-type-h1" title="Heading"></i>
        <i className="bi bi-plus" title="Add"></i>
        <i
          className="bi bi-chat-dots"
          title="Collapse"
          onClick={toggleCollapse}
        ></i>
      </div>
      <Dialog open={editModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Edit Flashcard</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit your flashcard details below.
          </DialogContentText>
          <label>Question</label>
          <ReactQuill
            label="Question"
            variant="outlined"
            value={editQuestion}
            onChange={setEditQuestion}
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],
                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ direction: "rtl" }], // text direction
                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],
                ["clean"], // remove formatting button
              ],
            }}
            formats={[
              "header",
              "font",
              "size",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "code-block",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
              "video",
              "color",
              "background",
              "align",
              "script",
               { name: 'preserve-spaces', type: 'inline' },
            ]}
          />
          <label>Answer</label>
          <ReactQuill
            label="Answer"
            variant="outlined"
            value={editAnswers}
            onChange={setEditAnswers}
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],
                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ direction: "rtl" }], // text direction
                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],
                ["clean"], // remove formatting button
              ],
            }}
            formats={[
              "header",
              "font",
              "size",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "code-block",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
              "video",
              "color",
              "background",
              "align",
              "script",
               { name: 'preserve-spaces', type: 'inline' },
            ]}
          />
          <label>Explanation</label>
          <ReactQuill
            label="Explanation"
            variant="outlined"
            value={editExplanation}
            onChange={setEditExplanation}
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],
                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ direction: "rtl" }], // text direction
                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],
                ["clean"], // remove formatting button
              ],
            }}
            formats={[
              "header",
              "font",
              "size",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "code-block",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
              "video",
              "color",
              "background",
              "align",
              "script",
               { name: 'preserve-spaces', type: 'inline' },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleUpdateCard}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Flashcard</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this flashcard?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleDeleteCard}>Delete</Button>
        </DialogActions>
      </Dialog>
      <TagModal
        open={tagModalOpen}
        position={tagModalPosition}
        onClose={() => setTagModalOpen(false)}
        subfolderId={subfolder_id}
        cardId={cards[tagCardIndex]?.id}
        cardQuestionType={cards[tagCardIndex]?.question_type}
        onTagAdded={handleTagAdded}
      />
    </div>
  );
};

export default Flashcard;
